<template>
  <div class="d-flex align-center flex-nowrap">
    <!-- viewType: {{viewType}}, viewTypeText : {{viewTypeText}}, computedViewType: {{computedViewType}} -->
    <document-ref-icon
      :visible="showIcon"
      :icon-name="iconName"
      :mixed="mixed"
      :x-small="_iconProps.xsmall"
      :small="_iconProps.small"
      :normal="_iconProps.normal"
      :large="_iconProps.large"
      :x-large="_iconProps.xlarge"
    />
    <div class="d-flex flex-column">
      <span
        :class="{ 'shadow-text': isNew }"
        class="text-truncate max-width-text"
      >{{name}}</span>
      <span
        class="align-baseline nowrap d-flex flex-row"
        v-if="innerOptions.showDetail"
      >
        <slot name="doc-type" />
        <span class="ref-detail">
          <span class="mx-1">{{$t('t.On').toLowerCase()}}</span>
          <span class="nowrap text-truncate max-width-text">"{{viewTypeText}}"</span>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import ClientCache from '@/mixins/client-cache'

export default {
  inheritAttrs: false,
  components: {
    DocumentRefIcon: () => import('@/components/document-ref-icon')
  },
  computed: {
    _iconProps () {
      const _ = Object.assign({
        xsmall: false,
        small: false,
        normal: false,
        large: false,
        xlarge: false
      }, this.iconProps)

      return _
    },
    isNew () {
      return this.id == null
    },
    name () {
      return this.isNew ? this.$t('t.NewColumnSet') : this.cache?.doc?.name ?? this.$t('t.None')
    },
    cacheType () {
      return ClientCache.CacheType.ColumnSetRef
    },
    innerOptions () {
      return {
        showDetail: !!this.options?.showDetail
      }
    },
    computedViewType () {
      return this.id ? this.cache?.doc?.viewType : this.viewType
    },
    viewTypeText () {
      return this.computedViewType ? this.$t(`t.${this.toPascalCase(this.computedViewType)}`) : ''
    },
    viewTypeIcon () {
      return this.computedViewType ? `i.${this.toPascalCase(this.computedViewType)}` : ''
    }
  },
  mixins: [ClientCache],
  props: {
    id: String,
    options: Object,
    mixed: Boolean,
    showIcon: Boolean,
    iconName: String,
    dense: Boolean,
    viewType: String
  }
}
</script>

<style lang="stylus" scoped>
.max-width-text
  max-width 250px
</style>
